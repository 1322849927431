<template>
  <div style="width: 95%;margin: 0 auto;text-align: justify;">
    <p>《消防课堂 APP隐私政策》</p>
    <p>【生效时间】：2023-5-11</p>
    <p>【更新时间】：2023-5-11</p>
    <p>
        “消防课堂 APP”平台通过以下条款为您提供服务.您应当阅读并遵守《隐私保护服务协议》(以下简称为“本协议”)，本协议是您与“消防课堂 APP”之间的法律协议。您在使用“消防课堂 APP”提供的各项服务之前，请您务必审慎阅读、充分理解各条款内容，特别是隐私保护、用户信息、免责以及争议管辖与法律适用的条款。除非您已阅读并接受本协议所有条款，否则您无权使用“消防课堂 APP”提供的服务。您使用“消防课堂 APP”的服务即视为您已阅读并同意本协议的约束，包括“消防课堂 APP”对本协议服务条款随时做的任何修改。
    </p>
    <p></p>
    <p>一、服务说明</p>
    <p>
        “消防课堂 APP”的具体服务内容由“消防课堂 APP”根据实际情况提供，
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">如照片上传功能、私信功能、在线投票、在线参加活动等。</span>
        “消防课堂 APP”保留变更、中断或终止部分网络服务的权利.“消防课堂 APP”保留根据实际情况随时调整“消防课堂 APP”平台提供的服务种类、形式的权利.“消防课堂 APP”不承担因业务调整给用户造成的损失。“消防课堂 APP”仅提供相关服务，除此之外与本服务有关的设备(如电脑、调制解调嚣及其他与接入互联网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费)均应由用户自行负担。
    </p>
    <p></p>
    <p>二、隐私保护</p>
    <p>
        1.用户在注册账号或使用本服务的过程中，<span style="text-decoration: underline;color: #29a4ec;">可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件(以下称“法律法规”)规定的需要填写的身份信息。</span>
        如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">2.个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、用户聊天记录。
        非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在消防课堂 APP服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。</span>
    </p>
    <p>
        3.尊重用户个人隐私信息的私有性是消防课堂 APP的一贯制度，消防课堂 APP将采取技术措施和其他必要措施，<span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">确保用户个人隐私信息安全，防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。</span>在发生前述情形或者消防课堂 APP发现存在发生前述情形的可能时，将及时采取补救措施。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">4.消防课堂 APP未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外：</span>
    </p>
    <p>
        (1)消防课堂 APP根据法律法规规定或有权机关的指示提供用户的个人隐私信息；
    </p>
    <p>
        (2)由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因消防课堂 APP原因导致的个人隐私信息的泄露；
    </p>
    <p>(3)用户自行选择向第三方公开其个人信息；</p>
    <p>
        (4)用户与消防课堂 APP及合作单位之间就用户个人信息的使用公开达成约定，消防课堂 APP因此向合作单位公开用户个人隐私信息；
    </p>
    <p>
        (5)任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露；
    </p>
    <p>(6)本协议约定的其他情形等。</p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">5.用户同意消防课堂 APP可在以下事项中使用用户的个人信息：</span>
    </p>
    <p>
        (1)消防课堂 APP向用户及时发送重要通知，如软件更新、本协议条款的变更;
    </p>
    <p>
        (2)消防课堂 APP内部进行审计、数据分析和研究等，以改进消防课堂 APP的产品、服务和与用户之间的沟通；
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">(3)依本协议约定，用户公开其个人信息、后台管理、审查用户信息及进行处理措施；</span>
    </p>
    <p>
        (4)适用法律法规规定的其他事项。除上述事顶外，如未取得用户同意，消防课堂 APP不会将用户个人隐私信息使用于任何其他用途。
    </p>
    <p>
        6.为了改善消防课堂 APP的技术和服务，向用户提供更好的服务体验，消防课堂 APP或可会自行收集使用或向第三方提供用户的非个人隐私信息。
    </p>
    <p></p>
    <p>三、用户信息收集</p>
    <p>
        消防课堂 APP提供平台服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为消防课堂 APP的用户或无法享受消防课堂 APP提供的某些服务，或者无法达到相关服务拟达到的效果。
    </p>
    <p>1.您向消防课堂 APP提供的信息</p>
    <p>
        2.在您使用服务过程中收集的信息为了提供并优化您需要的服务，消防课堂 APP会收集您使用服务的相关信息，这类信息
    </p>
    <p>3.来自第三方的信息</p>
    <p>您了解并同意，以下信息不适用本隐私保护条款：</p>
    <p>
        您知悉并认可：消防课堂 APP可能会与第三方合作向用户提供相关的网络服务，在此情况下，如消防课堂 APP旗下相应主体已与该第三方签署了适当的保密条款，则消防课堂 APP有权将用户的注册信息等资料提供给该第三方，并要求该第三方按照消防课堂 APP的隐私保护条款以及其他任何相关的保密和安全措施来为消防课堂 APP处理上述信息。另外，在不透露单个用户隐私资料的前提下，消防课堂 APP有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。消防课堂 APP可能会对网络服务使用情况进行统计。同时，消防课堂 APP可能会与公众分享这些统计信息，以展示消防课堂 APP服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。
    </p>
    <p></p>
    <p>四、信息使用的目的和方式</p>
    <p>
        因收集您的信息是为了向您提供服务及提升服务质量的目的，为了实现这一目的，消防课堂 APP会把您的信息用于下列用途：
    </p>
    <p>1.向您提供您使用的各项服务，并维护、改进这些服务。</p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">2.消防课堂 APP可能使用您提供的个人信息中的联系方式与您通信，例如通知您有关您的账户、安全性更新、产品和服务信息。</span>为了更好的为您提供服务，请您及时查看消防课堂 APP发送的相关信息。
    </p>
    <p>
        3.如果您对消防课堂 APP的内容不感兴趣，请及时回复取消内容的通知。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">4.消防课堂 APP可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与消防课堂 APP或其关联方协议、政策或规则的行为，</span>以保护您、其他消防课堂 APP用户，或消防课堂 APP或其关联方的合法权益。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">5.消防课堂 APP可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加的服务使用，</span>例如让您拥有更广泛的社交圈的需要而使用、共享或披露。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">6.消防课堂APP 可能使用您的相关设备及网络信息，以便为您提供持续稳定的服务支持（如数据的实时同步），同时有助于不断改善自身产品和服务，从而使您在使用过程中获得最佳体验。相关设备信息包括设备名称、 设备型号、硬件序列号、OAID、IMEI、Android ID、操作系统和应用程序版本及类型、登录IP地址等。</span>
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">7.消防课堂 APP不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的同意，</span>或该第三方和消防课堂 APP及其关联方单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。您知悉并认可：消防课堂 APP通过广告或其他方式向您提供链接，使您可以接入第三方服务或网站。您使用该等第三方的服务时，须受该第三方的服务条款及隐私条款约束，您需要仔细阅读其条款。本协议仅适用于消防课堂 APP提供的服务嚣所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，消防课堂 APP对任何第三方使用由您提供的信息不承担任何责任。
    </p>
    <p></p>
    <p>五、用户注册</p>
    <p>
        1.您确认，在您完成注册程序或以其他消防课堂 APP允许的方式实际使用消防课堂 APP平台服务时，您应当是具备相应民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人(以下统称“监护人”)应承担因此而导致的一切后果，且消防课堂 APP有权注销您的账户，并向您及您的监护人索偿。
    </p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">2.消防课堂 APP非常重视对青少年个人信息搜集和使用的安全性的保护。</span>消防课堂 APP走议，任何未满18周岁的未成年人参加网上活动应事先取得其监护人的同意并遵守《全国青少年网络文明公约》。监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。
    </p>
    <p>
        3.如发现您的用户账号中含有不雅文字或不恰当名称的，消防课堂 APP平台保留取消用户资格的权利.请勿以党和国家领导人或其他社会名人真实姓名、字号、艺名、笔名注册；请勿以国家机构或其他机构名称注册、请勿注册不文明、不健康的名称，及包含歧视、侮辱、猥亵类词语的名称；请勿注册易产生歧义、引起他人误解或其他不符合法律规定的账号；用户自己有义务保证账号和密码的安全，用户利用该账号和密码进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本平台不承担任何责任。
    </p>
    <p>
        4.您了解并同意，一经注册用户账号，即视为您同意消防课堂 APP及/或其关联公司可以<span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息；</span>
        您如果不同意接收相关信息，您可以通过客服或相关提示进行退订。
        4.如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应该立即修改帐号密码并妥善保管，如有必要，请通知本平台，因黑客行为或用户的保管疏忽导致账号非法使用，消防课堂 APP平台不承担任何责任。
    </p>
    <p>
        5.您了解并同意，一经注册用户账号，即视为您同意消防课堂 APP及/或其关联公司可以
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">通过拨打电话、发送短信或者电子邮件等方式向您注册时填写的手机号码、电子邮箱等发送、告知相应的产品服务广告信息、促销优惠等营销信息；</span>
        您如果不同意接收相关信息，您可以通过客服或相关提示进行退订。
    </p>
    <p></p>
    <p>六、用户行为</p>
    <p>
        用户同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为：
    </p>
    <p>1.发布或以其它方式传送含有下列内容之一的信息：</p>
    <p>2.以人任何方式危害他人的合法权益；</p>
    <p>
        3.冒充其他任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
    </p>
    <p>
        4.将依据任何法律或合约或法定关系(例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料)知悉但无权传送之任何内容加y发布、发送电子邮件或以其它方式传送；
    </p>
    <p>
        5.将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利(以下简称“专属权利”)之内容加以发布或以其它方式传送；
    </p>
    <p>
        6.将任何广告信函、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以发布、发送或以其它方式传送；
    </p>
    <p>
        7.将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒(包括但不限于木马程序(trojanhorses)、蠕虫(worms)、定时炸弹、删除蝇(cancelbots)(以下简称“病毒”)或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送；
    </p>
    <p>
        8.干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；
    </p>
    <p>9.跟踪、人肉搜索或以其它方式骚扰他人；</p>
    <p>
        10.故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；
    </p>
    <p>
        11.<span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，</span>或将获知的此类资料用于任何非法或不正当目的。您已认可“消防课堂 APP”未对用户的使用行为进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或实用性时，您同意将自行加以判断并承担所有风险，而不依赖于“消防课堂 APP”。但“消防课堂 APP”依其自行之考虑，可拒绝和册除经由本服务提供之违反本条款的或其它引起“消防课堂 APP”反感的任何内容。您了解并同意，“消防课堂 APP”依据法律法规的要求，或基于诚信为了以下目的或在合理必要范围内，认定必须将内容加以保存或揭露时，得加以人保存或揭露：
    </p>
    <p>1.遵守法律程序；</p>
    <p>2.执行本使用协议；</p>
    <p>3.回应任何第三人提出的权利主张；</p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">4.保护“消防课堂 APP”、其用户及公众之权利、财产或个人安全；</span>
    </p>
    <p>5.其它“消防课堂 APP”认为有必要的情况；</p>
    <p></p>
    <p>七、知识产权</p>
    <p>
        “消防课堂 APP”的整体内容版权属于清大东方教育科技集团有限公司。“消防课堂 APP”所有的产品、技术与所有程序均属于“消防课堂 APP”知识产权，在此并未授权。“消防课堂 APP”所有设计图样以及其他图样、产品及服务名称，均为清大东方教育科技集团有限公司所享有的商标、标识。任何人不得使用、复制或用作其他用途。未经消防课堂 APP许可，任何单位和个人不得私自复制、传播、展示、镜像、上载、下载、使用，或者从事任何其他侵犯消防课堂 APP知识产权的行为。否则，消防课堂 APP将追究相关法律责任。
    </p>
    <p></p>
    <p>八、内容所有权</p>
    <p>
        “消防课堂 APP”提供的本服务内容可能包括：<span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">文字、软件、声音、图片、录象、图表等。</span
        >所有这些内容受版权、商标和其它财产所有权法律的保护。用户只有在获得“消防课堂 APP”或其他相关权利人的授权之后才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
    </p>
    <p></p>
    <p>九、遵守法律</p>
    <p>
        您同意遵守中华人民共和国相关法律法规的所有规定，并对以任何方式使用您的密码和您的帐号使用本服务的任何行为及其结果承担全部责任。如您的行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。同时，如果“消防课堂 APP”有理由认为您的任何行为，包括但不限于您的任何言论和其它行为违反或可能违反国家法律和法规的任何规定，“消防课堂 APP”可在任何时候不经任何事先通知终止向您提供服务。
    </p>
    <p></p>
    <p>十、与广告商进行交易</p>
    <p>
        用户通过本服务与广告商进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为用户与广告商之间之行为。用户因前述任何交易或前述广告商而遭受的任何性质的损失或损害，“消防课堂 APP”均不予负责。
    </p>
    <p></p>
    <p>十一、免责声明</p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">1.互联网是一个开放平台，用户将照片等个人或商户资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。</span>用户明确同意其使用“消防课堂 APP”服务所存在的风险将完全由其自己承担；因其使用“消防课堂 APP”服务而产生的一切后果也由其自己承担，消防课堂 APP对用户不承担任何责任。消防课堂 APP不保证服务一定能满足用户的要求，也不保证服务不会中断，对服务的及时性、安全性、准确性也都不作保证。对于因不可抗力或“消防课堂 APP”无法控制的原因造成的网络服务中断或其他缺陷，“消防课堂 APP”不承担任何责任。消防课堂 APP不对用户所发布信息的删除或储存失败承担责任。消防课堂 APP有权判断用户的行为是否符合本网站使用协议条款之规定，如果消防课堂 APP认为用户违背了协议条款的规定，消防课堂 APP有终止向其提供网站服务的权利。
    </p>
    <p>
        2.您了解并同意，消防课堂 APP不对因下述任一情况而导致您的任何损害、赔偿承担责任，包括但不限于经济、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿。
    </p>
    <p>
        3.消防课堂 APP对下列不可抗力行为免责：信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务。
    </p>
    <p>
        4.您应当严格遵守本协议及消防课堂 APP发布的其他协议、活动规则、站内公告等，因您违反协议或规则的行为给第三方或消防课堂 APP造成损失的，您应当承担全部责任。
    </p>
    <p></p>
    <p>十二、活动规则</p>
    <p>
        如用户以任何不正当手段或违规行为参与平台任何活动，一经发现，消防课堂 APP有权对该用户账号进行异常标记。对于异常用户会采取邀请的新用户记为无效拉新、取消活动资格、不发放奖励、回收奖励等措施，并保留追究该用户责任的权利。如因此给用户造成损失，不进行任何赔偿或补偿。不正当手段及舞弊行为包括但不限于：使用非法工具分享、下载、安装、注册、登录多个账号及其他不正当手段。
    </p>
    <p></p>
    <p>十三、附则</p>
    <p>
        本用户条款的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如用户就本协议内容或其执行发生任何争议，用户应尽量与消防课堂 APP友好协商解决；协商不成时，任何一方均可向“消防课堂 APP”所在地的人民法院提起诉讼。消防课堂 APP未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。如本协议条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议条款的其余条款仍应有效并且有约束力。本协议所有条款的最终解释权属于“消防课堂 APP”。
    </p>
    <p></p>
    <p>十四、注销账号</p>
    <p>您可以通过以下方式申请注销您的账户：</p>
    <p></p>
    <p>
        1、进入【消防课堂 APP】手机客户端，选择“我的-安全管理-账号注销”，您可以注销您的账户。我们会在3个工作日注销你的账号。
    </p>
    <p></p>
    <p>
        在您主动注销账户之后，我们将停止为您提供商品或服务，并根据适用法律的要求删除您的个人信息，或使其匿名化处理。
    </p>
    <p></p>
    <p></p>
    <p>
        为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p></p>
    <p>具体如下：</p>
    <p></p>
    <p></p>
    <p>SDK简称：友盟+移动统计</p>
    <p></p>
    <p>合作方：友盟同欣（北京）科技有限公司</p>
    <p></p>
    <p>
        合作目的：提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
    </p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：采集设备标识符(IMEI/Mac/IDFA/OPENUDID/GUID、SIM 卡 IMSI信息)、位置信息、获取设备序列号、获取Android_ID
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：
        https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：友盟应用性能监控SDK</p>
    <p></p>
    <p>合作方：友盟同欣（北京）科技有限公司</p>
    <p></p>
    <p>
        合作目的：提供设备应用性能监控服务，通过采集位置信息提供反作弊服务，剔除作弊设备，排查应用性能崩溃原因
    </p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：采集设备标识符(IMEI/Mac/android
        ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)、位置信息
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：
        https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：友盟社会化分享SDK</p>
    <p></p>
    <p>合作方：友盟同欣（北京）科技有限公司</p>
    <p></p>
    <p>合作目的：向目标设备分享消息</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：采集设备标识符(IMEI/Mac/android
        ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)、位置信息
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：
        https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：友盟智能认证 SDK</p>
    <p></p>
    <p>合作方：友盟同欣（北京）科技有限公司</p>
    <p></p>
    <p>
        合作目的：用于提供手机号码一键登录服务。采集地理位置甄别分享通道，提供反作弊服务。
    </p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：采集设备标识符(IMEI/Mac/android
        ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)以及手机号码
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.547034dcafEUZJ
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：中国移动号码认证SDK</p>
    <p></p>
    <p>合作方：中国移动</p>
    <p></p>
    <p>合作方类型：免密号码友盟合作方</p>
    <p></p>
    <p>合作目的：提供移动端设备移动手机号免密号码验证服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>个人信息收集范围：当前手机号的掩码</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://wap.cmpassport.com/resources/html/contract.html</p>
    <p></p>
    <p></p>
    <p>SDK简称：中国联通号码认证SDK</p>
    <p></p>
    <p>合作方：中国联通</p>
    <p></p>
    <p>合作方类型：免密号码友盟合作方</p>
    <p></p>
    <p>合作目的：提供移动端设备联通手机号免密号码验证服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>个人信息收集范围：当前手机号的掩码</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：中国电信号码认证SDK</p>
    <p></p>
    <p>合作方：中国电信</p>
    <p></p>
    <p>合作方类型：免密号码友盟合作方</p>
    <p></p>
    <p>合作目的：提供移动端设备电信手机号免密号码验证服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>个人信息收集范围：当前手机号的掩码</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：https://e.189.cn/sdk/agreement/content.do?type=main&amp;appKey=&amp;hidetop=true&amp;returnUrl=
    </p>
    <p></p>
    <p></p>
    <p>SDK 名称：腾讯互联SDK</p>
    <p></p>
    <p>合作方：腾讯科技（深圳）有限公司</p>
    <p></p>
    <p>合作目的：QQ分享</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：查看WLAN连接；粗略定位；精确定位；读取外置存储卡；读取手机状态身份；写入外置存储卡；后台访问地理位置
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://wiki.connect.qq.com/qq互联sdk隐私保护声明</p>
    <p></p>
    <p></p>
    <p>SDK 名称：微信openSDK</p>
    <p></p>
    <p>合作方：腾讯科技（深圳）有限公司</p>
    <p></p>
    <p>合作目的：提供微信APP第三方登录分享服务和第三方支付服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8
    </p>
    <p></p>
    <p></p>
    <p>SDK 名称：支付宝SDK</p>
    <p></p>
    <p>合作方：蚂蚁金服网络技术有限公司</p>
    <p></p>
    <p>合作目的：使用支付宝授权在app内进行商品支付</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://render.alipay.com/p/c/k2cx0tg8</p>
    <p></p>
    <p></p>
    <p>SDK简称：美洽SDK</p>
    <p></p>
    <p>合作方：成都美洽网络科技有限公司</p>
    <p></p>
    <p>
        合作目的：美洽科技会将以上信息进行关联，以便能在不同设备上为用户提供一致的服务。进行app内部的在线沟通
    </p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：SDK收集数据的类型：采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）、网络信息以及地理位置信息，用于用户登录账户和支付过程中的安全风控
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://meiqia.com/privacy-clause</p>
    <p></p>
    <p></p>
    <p>SDK简称：腾讯x5浏览器内核</p>
    <p></p>
    <p>合作方：深圳市腾讯计算机系统有限公司</p>
    <p></p>
    <p>合作目的：移动浏览场景体验优化</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）、设备制造商、网络类型、网络状态、手机操作系统、屏幕尺寸、ip地址
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://x5.tencent.com/tbs/guide/develop.html#5</p>
    <p></p>
    <p></p>
    <p>SDK名称：腾讯 Bugly</p>
    <p>合作方：深圳市腾讯计算机系统有限公司</p>
    <p>隐私协议：https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</p>
    <p>服务类型：判断当前进程的ANR状态，排查崩溃问题,帮助APP提升稳定性</p>
    <p>
        收集个人信息类型：设备MAC地址、软件安装列表，手机型号、手机品牌、Android系统版本、Android系统api等级、厂商系统版本、cpu架构类型、设备是否root、磁盘空间占用大小、sdcard空间占用大小、内存空间占用大小、网络类型、应用当前正在运行的进程名和PID（仅读取，不保存到本地，也不上传服务器）
    </p>
    <p></p>
    <p>SDK简称：Zxing 二维码扫描 SDK</p>
    <p></p>
    <p>合作方：开源个人开发者</p>
    <p></p>
    <p>
        合作目的：在用户授权APP使用相机和相册的使用权限条件下，提供二维码识别功能和二维码生成功能
    </p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK</p>
    <p></p>
    <p>个人信息收集范围：不收集个人信息</p>
    <p></p>
    <p>隐私政策：个人开发者没有隐私协议</p>
    <p></p>
    <p></p>
    <p>SDK简称：统一SDK</p>
    <p></p>
    <p>合作方：移动安全联盟</p>
    <p></p>
    <p>合作目的：获取设备唯一标识符</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK</p>
    <p></p>
    <p>个人信息收集范围：UDID、OAID、VAID、AAID</p>
    <p></p>
    <p>隐私协议：http://msa-alliance.cn/col.jsp?id=120</p>
    <p></p>
    <p></p>
    <p>SDK简称：MobTech PushSDK</p>
    <p></p>
    <p>合作方：上海游昆信息技术有限公司</p>
    <p></p>
    <p>合作目的：为了实现消息功能</p>
    <p></p>
    <p>
        <span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息，对接第三方接口，接口传输个人信息</span>
    </p>
    <p></p>
    <p>
        个人信息收集范围：系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID
        、地理位置
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://www.mob.com/about/policy</p>
    <p></p>
    <p></p>
    <p>SDK简称：MobTech MobLinkSDK</p>
    <p></p>
    <p>合作方：上海游昆信息技术有限公司</p>
    <p></p>
    <p>合作目的：为了提供场景还原功能</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：系统运营信息、网络状态信息、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID
        、地理位置
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://www.mob.com/about/policy</p>
    <p></p>
    <p></p>
    <p>SDK简称：百度地图SDK</p>
    <p></p>
    <p>合作方：百度在线网络技术（北京）有限公司</p>
    <p></p>
    <p>合作类型：定位位置服务合作方</p>
    <p></p>
    <p>合作目的：提供用户位置服务，帮助用户在发布信息时定位位置</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：设备MAC地址、Android
        ID、唯一设备标识码、位置信息、Wi-Fi地址、陀螺仪传感器,加速度传感器,重力传感器,BSSID,SSID
    </p>
    <p></p>
    <p>隐私政策：http://lbsyun.baidu.com/indoor/indoormap/agree</p>
    <p></p>
    <p></p>
    <p>SDK简称：腾讯移动分析SDK（com.tencent.stat）</p>
    <p></p>
    <p>合作方：腾讯科技（深圳）有限公司</p>
    <p></p>
    <p>合作目的：实时数据统计分析服务，协助产品、运营和市场决策</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>
        个人信息收集范围：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）
    </p>
    <p></p>
    <p>隐私政策链接：https://privacy.qq.com/</p>
    <p></p>
    <p></p>
    <p>SDK简称：腾讯开放平台、QQ互联（com.tencent.open/com.tencent.tauth）</p>
    <p></p>
    <p>作方：腾讯科技（深圳）有限公司</p>
    <p></p>
    <p>合作目的：提供微信APP第三方登录分享服务和第三方支付服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        隐私政策：https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl
    </p>
    <p></p>
    <p></p>
    <p>SDK简称：阿里云日志服务</p>
    <p></p>
    <p>合作方：阿里云计算有限公司</p>
    <p></p>
    <p>个人信息收集方式：APP初始化SDK，SDK通过自己逻辑调用系统的API获取</p>
    <p></p>
    <p>个人信息收集范围：获取运行中进程信息</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://help.aliyun.com/document_detail/29056.html</p>
    <p></p>
    <p></p>
    <p>SDK简称：小米手机通道SDK</p>
    <p></p>
    <p>合作方：小米科技有限责任公司</p>
    <p></p>
    <p>合作方类型：消息服务合作方</p>
    <p></p>
    <p>合作目的：提供小米设备消息服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://dev.mi.com/console/doc/detail?pId=1698</p>
    <p></p>
    <p></p>
    <p>SDK简称：魅族手机通道SDK</p>
    <p></p>
    <p>合作方：珠海市魅族科技有限公司</p>
    <p></p>
    <p>合作方类型：消息服务合作方</p>
    <p></p>
    <p>合作目的：提供魅族设备消息服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p>隐私政策：http://open-wiki.flyme.cn/index.php</p>
    <p></p>
    <p></p>
    <p>SDK简称：OPPO手机通道SDK</p>
    <p></p>
    <p>合作方：广东步步高电子工业有限公司</p>
    <p></p>
    <p>合作方类型：消息服务合作方</p>
    <p></p>
    <p>合作目的：提供OPPO设备消息服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://open.oppomobile.com/wiki/doc#id=10194</p>
    <p></p>
    <p></p>
    <p>SDK简称：vivo手机通道SDK</p>
    <p></p>
    <p>合作方：维沃移动通信有限公司</p>
    <p></p>
    <p>合作方类型：消息服务合作方</p>
    <p></p>
    <p>合作目的：提供vivo设备消息服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613</p>
    <p></p>
    <p></p>
    <p>SDK简称：华为、荣耀手机通道SDK</p>
    <p></p>
    <p>合作方：华为技术有限公司</p>
    <p></p>
    <p>合作方类型：消息服务合作方</p>
    <p></p>
    <p>合作目的：提供华为、荣耀设备消息服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">隐私政策：https://consumer.huawei.com/cn/privacy/privacy-policy</p>
    <p></p>
    <p></p>
    <p>SDK简称：Exoplayer 视频播放SDK</p>
    <p></p>
    <p>合作方：开源项目</p>
    <p></p>
    <p>合作方类型：视频播放</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK</p>
    <p></p>
    <p>个人信息收集范围：不收集个人信息</p>
    <p></p>
    <p>项目地址：https://github.com/google/ExoPlayer/</p>
    <p></p>
    <p>隐私政策：开源项目没有隐私协议</p>
    <p></p>
    <p></p>
    <p>SDK简称：Gson SDK</p>
    <p></p>
    <p>合作方：开源项目</p>
    <p></p>
    <p>合作方类型：数据解析</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK</p>
    <p></p>
    <p>个人信息收集范围：不收集个人信息</p>
    <p></p>
    <p>项目地址：https://github.com/google/gson/</p>
    <p></p>
    <p>隐私政策：开源项目没有隐私协议</p>
    <p></p>
    <p></p>
    <p>SDK简称：OkHttp 网络请求SDK</p>
    <p></p>
    <p>合作方：开源</p>
    <p></p>
    <p>合作目的：提供网络请求服务</p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK</p>
    <p></p>
    <p>个人信息收集范围：ip</p>
    <p></p>
    <p>隐私政策：无</p>
    <p></p>
    <p></p>
    <p>SDK简称：com.commonlib.util</p>
    <p></p>
    <p>个人信息收集范围:写入SDCard数据、获取剪切板内容、AndroidID</p>
    <p></p>
    <p>隐私政策：无</p>
    <p></p>
    <p></p>
    <p>SDK简称：com.commonlib.BaseActivity</p>
    <p></p>
    <p>个人信息收集范围:读取系统设置、获取网络状态、获取剪切板内容</p>
    <p></p>
    <p>隐私政策：无</p>
    <p></p>
    <p></p>
    <p>SDK简称：com.commonlib.model</p>
    <p></p>
    <p>
        个人信息收集范围:写入SDCard数据、读取系统设置、获取设备IMEI、、获取网络状态、获取剪切板内容、获取WIFI名称
    </p>
    <p></p>
    <p>隐私政策：无</p>
    <p></p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">SDK简称：AndroidSupportLibraryFragment（androidx.fragment）</p>
    <p></p>
    <p>个人信息收集范围:写入SDCard数据、获取剪切板内容</p>
    <p></p>
    <p>隐私政策：无</p>
    <p></p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        SDK简称：Android Support Library View Pager（androidx.viewpager.widget）
    </p>
    <p></p>
    <p>合作方：Google</p>
    <p></p>
    <p>个人信息收集范围:读取系统设置、写入SDCard数据、获取剪切板内容</p>
    <p></p>
    <p>隐私政策：https://policies.google.com/privacy/</p>
    <p></p>
    <p></p>
    <p>SDK简称：美洽SDK（com.meiqia）</p>
    <p></p>
    <p>合作方：成都美洽网络科技有限公司</p>
    <p></p>
    <p>
        合作目的：美洽科技会将以上信息进行关联，以便能在不同设备上为用户提供一致的服务。进行app内部的在线沟通
    </p>
    <p></p>
    <p>个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</p>
    <p></p>
    <p>
        个人信息收集范围：SDK收集数据的类型：采集常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）、获取运行中进程信息、网络信息以及地理位置信息，用于用户登录账户和支付过程中的安全风控
    </p>
    <p></p>
    <p>隐私政策：https://meiqia.com/privacy-clause</p>
    <p></p>
    <p></p>
    <p>SDK简称：java-common-lib</p>
    <p></p>
    <p>合作方：sosy-lab</p>
    <p></p>
    <p>合作目的：提供本APP的技术支持服务</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：SDK收集传输个人信,对接口传输个人信息</span></p>
    <p></p>
    <p>个人信息收集范围：获取粘贴板信息 、获取Android_ID</p>
    <p></p>
    <p>隐私政策：https://github.com/sosy-lab/java-common-lib</p>
    <p></p>
    <p>SDK简称：极光sdk</p>
    <p>所属公司：深圳市和讯华谷信息技术有限公司</p>
    <p>SDK收集数据的类型：收集用户个人信息</p>
    <p>
        用途：收集、使用、存储、共享、转让您和/或您的终端用户的个人信息的目的、方式、范围和信息安全保护措施
    </p>
    <p>隐私政策链接：?https://www.jiguang.cn/license/privacy</p>
    <p></p>
    <p>SDK简称：阿里云移动数据分析</p>
    <p></p>
    <p>所属公司：杭州阿里云智能科技有限公司</p>
    <p></p>
    <p>使用目的：用于崩溃日志的上报及分析</p>
    <p></p>
    <p>使用场景：在用户使用App时使用</p>
    <p></p>
    <p>共享方式：APP初始化SDK，SDK通过自己逻辑调用系统的API获取</p>
    <p></p>
    <p>
        个人信息范围：网络信息，设备信息包括IMEI，Android系统设备标识（IMSIIMEISIM卡序列号AndroidIDMAC地址），IP地址，BSSID、SSID
    </p>
    <p></p>
    <p style="white-space: normal;word-break: break-all;">
        合作方隐私政策链接/官网链接：https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html
    </p>
    <p></p>
    <p>SDK简称：优量汇SDK（com.qq.e）</p>
    <p></p>
    <p>合作方：深圳市腾讯计算机系统有限公司</p>
    <p></p>
    <p>合作方类型:效果广告服务合作方</p>
    <p></p>
    <p>合作目的：提供商业化效果广告投放资源</p>
    <p></p>
    <p><span style="font-weight: bold; text-decoration: underline;color: #29a4ec;">个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</span></p>
    <p></p>
    <p>
        个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、获取Android_ID
    </p>
    <p></p>
    <p>隐私政策：https://e.qq.com/optout.html</p>
    <p></p>
    <p></p>
    <p>自启动和关联启动说明</p>
    <p></p>
    <p>
        1.为确保应用处于关闭或后台运行状态下可正常接收到客户端的广播，本应用需使用（自动动）能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动的行为，是因实现功能及服务必要的
    </p>
    <p></p>
    <p>
        2.当您打开APP内容类消息，在征得您的明确同意后会跳转打开相关内容。在未征得您同意的情况下，则不会有关联启动
    </p>
    <p></p>
    <p>3.当您打开APP内部下载的文件后，会关联启动第三方的APP</p>
    <p></p>
    <p>联系方式：400-1888-119</p>
    <p></p>
    <p>公司信息：清大东方教育科技集团有限公司</p>
    <p></p>
    <p></p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="stylus" scoped></style>